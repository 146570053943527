<template>
  <div @click="closeOpenSearch">
    <validation-observer ref="simpleRules">
      <b-form name="create-item" autocomplete="on">
        <b-row>
          <b-col cols="12">
            <b-card title="Main Data">
              <b-row>
                <b-col sm="12">
                  <div>
                    <!-- Row Loop -->
                    <b-row v-for="(item, index) in itemData.translations" :id="item.id" :key="item.id" ref="row">
                      <!-- translation language -->
                      <b-col md="4">
                        <validation-provider #default="{ errors }" name="language" rules="required">
                          <b-form-group label="Language" label-for="language" :state="errors.length > 0 ? false : null">
                            <v-select :id="'language' + index" v-model="tranLocaleSelect[index]" :state="tranLocaleSelect[index] === null ? false : true
                              " :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                " :options="localeOptions" :selectable="(option) =>
                                  !option.value.includes('select_value')
                                  " label="text" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- translation name -->
                      <b-col md="4">
                        <b-form-group label="Name" label-for="name">
                          <validation-provider #default="{ errors }" name="name" rules="required">
                            <b-form-input :id="'TranslationName' + index" autocomplete="on" :style="tranLocaleSelect[index].vlaue == 'ar'
                              ? 'text-align: right !important;'
                              : 'text-align: right !important;'
                              " v-model="itemData.translations[index].name"
                              :state="errors.length > 0 ? false : null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- translation short description -->
                      <b-col md="4">
                        <b-form-group label="Short Description" label-for="short-description">
                          <b-form-input :id="'short-description' + index" autocomplete="on" :style="tranLocaleSelect[index].vlaue == 'ar'
                            ? 'text-align: right !important;'
                            : 'text-align: right !important;'
                            " v-model="itemData.translations[index].short_description
                              " />
                        </b-form-group>
                      </b-col>
                      <!-- translation description -->
                      <b-col md="12">
                        <b-form-group label="Description" label-for="description">
                          <vue-editor :id="'translationDescription' + index" autocomplete="on"
                            v-model="itemData.translations[index].description" />
                        </b-form-group>
                      </b-col>
                      <!-- Remove Button -->
                      <b-col lg="2" md="3" class="mb-50">
                        <b-button v-show="!tranLocaleSelect[index].disa" v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                          variant="outline-danger" class="mt-0 mt-md-2" @click="translationsremoveItem(index)">
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <!-- add new button -->
                <b-col sm="2">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block
                    @click="translationsrepeateAgain">
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>Add New</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card title="General Details">
              <b-row>
                <!-- cover -->
                <b-col md="6">
                  <b-form-group label="Cover" label-for="cover">
                    <validation-provider #default="{ errors }" name="Cover" rules="required">
                      <b-form-file v-model="coverImage" @change="uploadImage" :state="errors.length > 0 ? false : null"
                        id="cover" accept="image/*" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Purchase Date -->
                <b-col md="6">
                  <validation-provider #default="{ errors }" name="Purchase Date" rules="required">
                    <b-form-group label="Purchase Date" label-for="purchase date"
                      :state="errors.length > 0 ? false : null">
                      <flat-pickr v-model="itemData.purchse_date" class="form-control" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Active -->
                <b-col md="4">
                  <validation-provider #default="{ errors }" name="active" rules="required">
                    <b-form-group label="Active" label-for="active" :state="errors.length > 0 ? false : null">
                      <v-select id="active" v-model="activeSelect" :state="activeSelect === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="activeOptions" :selectable="(option) => !option.value.includes('select_value')
                          " label="text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Status -->
                <b-col md="4">
                  <validation-provider #default="{ errors }" name="Status" rules="required">
                    <b-form-group label="Status" label-for="status" :state="errors.length > 0 ? false : null">
                      <v-select id="status" v-model="statusSelect" :state="statusSelect === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="statusOptions" :selectable="(option) => !option.value.includes('select_value')
                          " label="text" />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- is_guide Status -->
                <b-col cols="4">
                  <h4>Is Guide :</h4>
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group class="d-flex" id="checkbox-group-2" button-variant="outline-primary"
                      :aria-describedby="ariaDescribedby" name="checkbox-group-2">
                      <b-form-radio v-model="itemData.is_guide" v-bind:value="true">Yes</b-form-radio>
                      <b-form-radio class="ml-1" v-model="itemData.is_guide" v-bind:value="false">No</b-form-radio>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card title="Classifications">
              <b-row>
                <!-- category -->
                <b-col md="6">
                  <validation-provider #default="{ errors }" name="category" rules="required">
                    <b-form-group label="Category" label-for="category" :state="errors.length > 0 ? false : null">
                      <v-select id="category" v-model="categorySelect" @change="getItems"
                        :state="categorySelect === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="categoryOption" :selectable="(option) => !option.value.includes('select_value')
                          " label="text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- brand -->
                <b-col md="6">
                  <validation-provider #default="{ errors }" name="brand" rules="required">
                    <b-form-group label="Brand" label-for="brand" :state="errors.length > 0 ? false : null">
                      <v-select id="brand" v-model="brandSelect" @change="getItems"
                        :state="brandSelect === null ? false : true" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="brandOptions" :selectable="(option) => !option.value.includes('select_value')
                          " label="text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Item -->
                <b-col md="6">
                  <b-form-group label="Family" label-for="family">
                    <v-select id="family" v-model="itemSelect" :disabled="itemSe"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="itemOptions" :selectable="(option) => !option.value.includes('select_value')
                        " label="text" />
                  </b-form-group>
                </b-col>
                <!-- tags -->
                <b-col md="6">
                  <b-form-group label="Tag" label-for="tag">
                    <v-select multiple id="tag" mu v-model="tagSelect"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="tagOptions" :selectable="(option) => !option.value.includes('select_value')
                        " label="text" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card title="Media">
              <b-row>
                <b-col sm="12">
                  <div>
                    <!-- Row Loop -->
                    <b-row v-for="(item, index) in itemData.images" :id="item.id" :key="item.id">
                      <!-- Image Reapeted -->
                      <b-col md="4">
                        <b-form-group label="Image" label-for="image">
                          <validation-provider #default="{ errors }" name="image" rules="required">
                            <b-form-file v-model="itemDataImages[index].file" @change="uploadImage2($event, index)"
                              :state="errors.length > 0 ? false : null" :id="'image' + index" accept="image/*" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- image description -->
                      <b-col md="4">
                        <b-form-group label="Name" label-for="name">
                          <b-form-input :id="'imagedescription' + index" autocomplete="on"
                            v-model="itemData.images[index].description" />
                        </b-form-group>
                      </b-col>
                      <!-- Remove Button -->
                      <b-col md="1" class="mb-50">
                        <b-button v-if="index > 0" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger"
                          class="mt-0 mt-md-2" @click="removeItem(index)">
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <!-- add new button -->
                <b-col sm="2">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block
                    @click="repeateAgain">
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>Add New</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12" class="mt-5">
            <b-card title="Attributes and Values">
              <b-row>
                <b-col cols="12">
                  <div>
                    <!-- Row Loop -->
                    <b-row v-for="(item, index) in attrOptions" :key="index">
                      <b-col md="2" class="d-flex justify-content-center align-items-center">
                        <p class="text-primary mb-0 font-weight-bold h4">
                          {{ item.text }}
                        </p>
                      </b-col>

                      <b-col md="4">
                        <validation-provider #default="{ errors }" name="values" rules="required">
                          <b-form-group label="Values" label-for="values" :state="errors.length > 0 ? false : null">
                            <v-select multiple :id="'values' + index" v-model="valuesSelect[index]" :clearable="false"
                              :state="valuesSelect[index] === null ? false : true
                                " :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                  " :options="item.values" label="text" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <!-- add new button -->
                <b-col sm="12" v-if="attrOptions.length == 0">
                  <div class="alert alert-danger p-1" role="alert">
                    Please Select Category
                  </div>

                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card title="Specifications">
              <b-row>
                <b-col cols="12">
                  <!-- Row Loop -->
                  <b-row @focus="resultShowBund[index] = false" v-for="(item, index) in itemData.taxonomy" :style="taxLocaleSelect[index].value == 'ar'
                    ? 'direction:rtl;'
                    : 'direction:ltr;'
                    " :id="item.id" :key="item.id" ref="row">
                    <!-- Taxonomy locale -->
                    <b-col md="2">
                      <validation-provider #default="{ errors }" name="Locale" rules="required">
                        <b-form-group label-for="active" :state="errors.length > 0 ? false : null">
                          <!-- <v-select
                            :id="'locale' + index"
                            v-model="taxLocaleSelect[index]"
                            placeholder="Select Locale"
                            @input="selecteLanguage(index)"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="localeOptions"
                            :selectable="
                              (option) => !option.value.includes('select_value')
                            "
                            label="text"
                          /> -->
                          <v-select :id="'locale' + index" v-model="taxLocaleSelect[index]" placeholder="Select Locale"
                            disabled :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="localeOptions"
                            :selectable="(option) => !option.value.includes('select_value')
                              " label="text" />
                          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Taxonomy term -->
                    <b-col md="2">
                      <validation-provider #default="{ errors }" name="Term" rules="required">
                        <b-form-group label-for="term" :state="errors.length > 0 ? false : null">
                          <v-select :id="'locale' + index" placeholder="Select Term" v-model="termSelect[index]"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="termOptions[index]"
                            :selectable="(option) => !option.value.includes('select_value')
                              " label="text" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Taxonomy name -->
                    <b-col md="3">
                      <b-form-group label-for="name">
                        <validation-provider #default="{ errors }" name="taxonomy_name" rules="required">
                          <b-form-input :id="'name' + index" autocomplete="on" :style="taxLocaleSelect[index].vlaue == 'ar'
                            ? 'text-align: right !important;'
                            : 'text-align: right !important;'
                            " placeholder="Name" v-model="itemData.taxonomy[index].name"
                            :state="errors.length > 0 ? false : null" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Taxonomy description -->
                    <b-col md="3" v-if="
                      termSelect[index] != null &&
                      termSelect[index].text != 'مميزات اضافية' &&
                      termSelect[index].text != 'ملحوظات'
                    ">
                      <b-form-group label-for="description">
                        <validation-provider #default="{ errors }" name="taxonomy description" rules="required">
                          <b-form-textarea v-if="
                            itemData.taxonomy[index].name == 'المعالج' ||
                            itemData.taxonomy[index].name ==
                            'عدد أنوية المعالج' ||
                            itemData.taxonomy[index].name == 'WLAN' ||
                            itemData.taxonomy[index].name == 'GPS' ||
                            itemData.taxonomy[index].name ==
                            'معلومات عن الشاحن' ||
                            itemData.taxonomy[index].typeCheck == 'new-record'
                          " autocomplete="off" placeholder="Description" :id="findAndReplace(
                            itemData.taxonomy[index].name_en,
                            ' ',
                            '-'
                          )
                            " :name="findAndReplace(
                              itemData.taxonomy[index].name_en,
                              ' ',
                              '-'
                            )
                              " class="taxDesc" v-model="itemData.taxonomy[index].description"
                            :state="errors.length > 0 ? false : null" />
                          <b-form-input :list="itemData.taxonomy[index].name" v-else placeholder="Description" :id="findAndReplace(
                            itemData.taxonomy[index].name_en,
                            ' ',
                            '-'
                          )
                            " class="taxDesc" :name="findAndReplace(
                              itemData.taxonomy[index].name_en,
                              ' ',
                              '-'
                            )
                              " v-model="itemData.taxonomy[index].description" autocomplete="off"
                            :state="errors.length > 0 ? false : null" />
                          <datalist :id="itemData.taxonomy[index].name">
                            <option v-for="(ite, ind) in itemData.taxonomy[index]
                              .resultTaxonomySearch" :key="ind">
                              {{ ite.name }}
                            </option>
                          </datalist>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- Taxonomy description -->
                    <b-col md="9" v-else>
                      <b-form-group label-for="description">
                        <validation-provider #default="{ errors }" name="taxonomy description" rules="required">
                          <vue-editor :id="findAndReplace(
                            itemData.taxonomy[index].name_en,
                            ' ',
                            '-'
                          )
                            " :name="findAndReplace(
                              itemData.taxonomy[index].name_en,
                              ' ',
                              '-'
                            )
                              " @imageAdded="handleImageAdded" v-model="itemData.taxonomy[index].description"
                            :state="errors.length > 0 ? false : null" rows="20" :editor-toolbar="customToolbar"
                            autocomplete="on"></vue-editor>

                          <datalist id="my-list-id">
                            <option v-for="(ite, ind) in itemData.taxonomy[index]
                              .resultTaxonomySearch" :key="ind">
                              {{ ite.name }}
                            </option>
                          </datalist>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="1" v-if="
                      !(
                        itemData.taxonomy[index].name == 'المعالج' ||
                        itemData.taxonomy[index].name ==
                        'عدد أنوية المعالج' ||
                        itemData.taxonomy[index].name == 'WLAN' ||
                        itemData.taxonomy[index].name == 'GPS' ||
                        itemData.taxonomy[index].name ==
                        'معلومات عن الشاحن' ||
                        itemData.taxonomy[index].typeCheck == 'new-record'
                      )
                    ">
                      <b-button style="
                          padding: 7px;
                          margin: 6px;
                          width: 35px;
                          height: 35px;
                          float: right;
                        " v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" class="btn-icon rounded-circle"
                        @click="
                          searchResultBundle(
                            itemData.taxonomy[index].name,
                            termSelect[index].value,
                            taxLocaleSelect[index].value,
                            index,
                            itemData.taxonomy[index].description
                          )
                          ">
                        <feather-icon icon="SearchIcon" style="width: 15px; height: 15px" />
                      </b-button>
                    </b-col>
                    <!-- Remove Button -->
                    <b-col md="1" class="mb-50">
                      <b-button v-if="index > 0" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger"
                        class="mt-0 mb-0" style="margin-bottom: 0 !important" @click="taxonomyremoveItem(index)">
                        <feather-icon icon="XIcon" class="mr-25" />
                      </b-button>
                    </b-col>
                    <b-col cols="12">
                      <hr style="margin-top: 0 !important" />
                    </b-col>
                  </b-row>
                </b-col>
                <!-- add new button -->
                <b-col cols="12" v-if="itemData.taxonomy.length > 0">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
                    @click="taxonomyrepeateAgain">
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>Add New</span>
                  </b-button>
                </b-col>

                <!-- add new button -->
                <b-col sm="12" v-if="itemData.taxonomy.length == 0">
                  <div class="alert alert-danger p-1" role="alert">
                    Please Select Category
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="12">
            <b-card title="Prices">
              <b-row>
                <b-col sm="12">
                  <div>
                    <!-- Row Loop -->
                    <b-row v-for="(item, index) in itemData.prices" :id="item.id" :key="item.id">
                      <!-- price number -->
                      <b-col md="2">
                        <b-form-group>
                          <label>Price</label>
                          <validation-provider #default="{ errors }" rules="required|regex:\b\d+(?:\.\d\d?)?\b"
                            name="Number">
                            <b-form-input autocomplete="on" v-model="itemData.prices[index].price"
                              :state="errors.length > 0 ? false : null" placeholder="Enter Number Only" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- price date -->
                      <b-col md="3">
                        <validation-provider #default="{ errors }" name="price_date" rules="required">
                          <b-form-group label="Price Date" label-for="price_date"
                            :state="errors.length > 0 ? false : null" placeholder="Purchase Date">
                            <flat-pickr v-model="itemData.prices[index].date" class="form-control" />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- price currency -->
                      <b-col md="3">
                        <validation-provider #default="{ errors }" name="Currency" rules="required">
                          <b-form-group label="Currency" label-for="currency" :state="errors.length > 0 ? false : null">
                            <v-select :id="'language' + index" v-model="currencySelect[index]" :state="currencySelect[index] === null ? false : true
                              " :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                " :options="currencyOptions" :selectable="(option) =>
                                  !option.value.includes('select_value')
                                  " label="text" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <!-- Remove Button -->
                      <b-col md="1" class="mb-50">
                        <b-button v-if="index > 0" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger"
                          class="mt-0 mt-md-2" @click="pricesremoveItem(index)">
                          <feather-icon icon="XIcon" class="mr-25" />
                        </b-button>
                      </b-col>
                      <!-- price discription -->
                      <b-col md="12">
                        <b-form-group>
                          <label>Description</label>
                          <validation-provider #default="{ errors }" rules="required" name="price description">
                            <!-- <b-form-input autocomplete="on" v-model="itemData.prices[index].description" :state="errors.length > 0 ? false:null" /> -->
                            <vue-editor :id="'translationDescription' + index" autocomplete="on"
                              v-model="itemData.prices[index].description" :state="errors.length > 0 ? false : null" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
                <!-- add new button -->
                <b-col sm="2">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block
                    @click="pricesrepeateAgain">
                    <feather-icon icon="PlusIcon" class="mr-25" />
                    <span>Add New</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- submit button -->
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm">
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- error handelr -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">Alert</h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import { heightTransition } from '@core/mixins/ui/transition'
import BCardCode from '@core/components/b-card-code'
export default {
  components: {
    VueEditor,
    BCardCode,
    flatPickr,
  },

  mixins: [heightTransition],
  data() {
    return {
      selected: false,
      mountedform: false,
      clearData: false,
      errors_back: [],
      showDismissibleAlert: false,
      //     refheightForm: '',
      number: '',
      ProfilePictureBase64: [],
      coverImage: [],
      itemImag: [],
      itemDataImages: [
        {
          file: [],
        },
      ],
      categorySelect: {
        value: 'select_value',
        text: 'Select Value',
      },
      categoryOption: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      brandSelect: null,
      brandOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      tagSelect: [{ value: '30', text: 'Mobile' }],
      tagOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      activeSelect: {
        value: '1',
        text: 'True',
      },
      activeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: '1',
          text: 'True',
        },
        {
          value: '0',
          text: 'False',
        },
      ],
      statusSelect: {
        value: 'enabled',
        text: 'Enabled',
      },
      statusOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'enabled',
          text: 'Enabled',
        },
        {
          value: 'disabled',
          text: 'Disabled',
        },
        {
          value: 'reviewed',
          text: 'Reviewed',
        },
        {
          value: 'archieved',
          text: 'Archieved',
        },
      ],
      termSelect: [
        {
          text: 'المجموعه 1',
          slug: 'المجموعه-1',
          value: '',
        },
        {
          text: 'المجموعه 2',
          slug: 'المجموعه-2',
          value: '',
        },
        {
          text: 'المجموعه 2',
          slug: 'المجموعه-2',
          value: '',
        },
        {
          text: 'المجموعه 2',
          slug: 'المجموعه-2',
          value: '',
        },
        {
          text: 'المجموعه 2',
          slug: 'المجموعه-2',
          value: '',
        },
        {
          text: 'المجموعه 3',
          slug: 'المجموعه-3',
          value: '',
        },
        {
          text: 'المجموعه 3',
          slug: 'المجموعه-3',
          value: '',
        },
        {
          text: 'المجموعه 3',
          slug: 'المجموعه-3',
          value: '',
        },
        {
          text: 'المجموعه 4',
          slug: 'المجموعه-4',
          value: '',
        },
        {
          text: 'المجموعه 4',
          slug: 'المجموعه-4',
          value: '',
        },
        {
          text: 'المجموعه 4',
          slug: 'المجموعه-4',
          value: '',
        },
        {
          text: 'المجموعه 4',
          slug: 'المجموعه-4',
          value: '',
        },
        {
          text: 'المجموعه 4',
          slug: 'المجموعه-4',
          value: '',
        },
        {
          text: 'المجموعه 4',
          slug: 'المجموعه-4',
          value: '',
        },
        {
          text: 'المجموعه 5',
          slug: 'المجموعه-5',
          value: '',
        },
        {
          text: 'المجموعه 5',
          slug: 'المجموعه-5',
          value: '',
        },
        {
          text: 'المجموعه 5',
          slug: 'المجموعه-5',
          value: '',
        },
        {
          text: 'المجموعه 5',
          slug: 'المجموعه-5',
          value: '',
        },
        {
          text: 'المجموعه 5',
          slug: 'المجموعه-5',
          value: '',
        },
        {
          text: 'المجموعه 6',
          slug: 'المجموعه-6',
          value: '',
        },
        {
          text: 'المجموعه 6',
          slug: 'المجموعه-6',
          value: '',
        },
        {
          text: 'المجموعه 7',
          slug: 'المجموعه-7',
          value: '',
        },
        {
          text: 'المجموعه 7',
          slug: 'المجموعه-7',
          value: '',
        },
        {
          text: 'المجموعه 7',
          slug: 'المجموعه-7',
          value: '',
        },
        {
          text: 'المجموعه 7',
          slug: 'المجموعه-7',
          value: '',
        },
        {
          text: 'المجموعه 7',
          slug: 'المجموعه-7',
          value: '',
        },
        {
          text: 'المجموعه 7',
          slug: 'المجموعه-7',
          value: '',
        },
        {
          text: 'المجموعه 7',
          slug: 'المجموعه-7',
          value: '',
        },
        {
          text: 'المجموعه 8',
          slug: 'المجموعه-8',
          value: '',
        },
        {
          text: 'المجموعه 8',
          slug: 'المجموعه-8',
          value: '',
        },
        {
          text: 'المجموعه 8',
          slug: 'المجموعه-8',
          value: '',
        },
        {
          text: 'المجموعه 8',
          slug: 'المجموعه-8',
          value: '',
        },
        {
          text: 'المجموعه 8',
          slug: 'المجموعه-8',
          value: '',
        },
        {
          text: 'المجموعه 8',
          slug: 'المجموعه-8',
          value: '',
        },
        {
          text: 'المجموعه 9',
          slug: 'المجموعه-9',
          value: '',
        },
        {
          text: 'المجموعه 9',
          slug: 'المجموعه-9',
          value: '',
        },
        {
          text: 'المجموعه 9',
          slug: 'المجموعه-9',
          value: '',
        },
        {
          text: 'المجموعه 9',
          slug: 'المجموعه-9',
          value: '',
        },
        {
          text: 'المجموعه 9',
          slug: 'المجموعه-9',
          value: '',
        },
        {
          text: 'المجموعه 9',
          slug: 'المجموعه-9',
          value: '',
        },
        {
          text: 'المجموعه 10',
          slug: 'المجموعه-10',
          value: '',
        },
        {
          text: 'المجموعه 10',
          slug: 'المجموعه-10',
          value: '',
        },
        {
          text: 'المجموعه 10',
          slug: 'المجموعه-10',
          value: '',
        },
        {
          text: 'المجموعه 10',
          slug: 'المجموعه-10',
          value: '',
        },
        {
          text: 'المجموعه 10',
          slug: 'المجموعه-10',
          value: '',
        },
        {
          text: 'المجموعه 10',
          slug: 'المجموعه-10',
          value: '',
        },
        {
          text: 'المجموعه 11',
          slug: 'المجموعه-11',
          value: '',
        },
        {
          text: 'المجموعه 11',
          slug: 'المجموعه-11',
          value: '',
        },
        {
          text: 'المجموعه 11',
          slug: 'المجموعه-11',
          value: '',
        },
        {
          text: 'المجموعه 12',
          slug: 'المجموعه-12',
          value: '',
        },
        {
          text: 'المجموعه 12',
          slug: 'المجموعه-12',
          value: '',
        },
        {
          text: 'المجموعه 13',
          slug: 'المجموعه-13',
          value: '',
        },
      ],
      termOptions: [],
      localeSelect: null,
      taxLocaleSelect: [
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
      ],
      tranLocaleSelect: [
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
      ],
      localeOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      currencySelect: [null],
      currencyOptions: [],

      resultShowBund: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      itemData: {
        purchse_date: '',
        is_guide: false,
        values: [],
        cover: [],
        active: '',
        status: '',
        category_id: '',
        brand_id: '',
        parent_id: '',
        images: [
          {
            file: [],
            description: null,
          },
        ],
        translations: [
          {
            locale: '',
            name: '',
            short_description: null,
            description: null,
          },
        ],
        prices: [
          {
            description: null,
            price: '',
            date: '',
            currency_id: '',
          },
        ],
        tags: [],
        taxonomy: [],
      },
      standardTaxonomy: [
        {
          term_id: '',
          name: 'سنة إصدار الموبايل',
          name_en: 'the year of the mobile version',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [
            {
              name: 'نة إصدار',
            },
          ],
        },
        {
          term_id: '',
          name: 'أبعاد الموبايل',
          name_en: 'mobile dimensions',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [
            {
              name: 'mobile',
            },
          ],
        },
        {
          term_id: '',
          name: 'خامات تصنيع الموبايل',
          name_en: 'mobile manufacturing materials',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'وزن الموبايل',
          name_en: 'mobile weight',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'عدد الشرائح',
          name_en: 'number of sim',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'نوع الشريحة',
          name_en: 'sim type',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'شبكات الموبايل',
          name_en: 'Phone sim',
          locale: '',
          description: null,
          taxDis: false,
        },
        {
          term_id: '',
          name: 'حجم الشاشة',
          name_en: 'screen size',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'أبعاد الشاشة',
          name_en: 'screen dimensions',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'تقنية الشاشة',
          name_en: 'screen technology',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'نسبة استحواذ الشاشة بالنسبة للموبايل',
          name_en: 'screen acquisition ratio for mobile',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'كثافة البيكسل في الشاشة',
          name_en: 'screen pixel density',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'دقة الشاشة',
          name_en: 'screen resolution',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'نظام التشغيل',
          name_en: 'screen resolution',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'المعالج',
          name_en: 'cpu',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'عدد أنوية المعالج',
          name_en: 'the number of processor cores',
          locale: '',
          description: null,
          taxDis: false,
        },
        {
          term_id: '',
          name: 'معالج الرسوميات',
          name_en: 'gpu',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'مساحة الذاكرة الداخلية + مساحة الرام',
          name_en: 'internal memory space and ram space',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'كارت ميموري',
          name_en: 'memory card',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'عدد الكاميرات الخلفية',
          name_en: 'number of rear cameras',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'الكاميرا الأولى + فتحة العدسة',
          name_en: 'first camera + aperture',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'الكاميرا الثانية + فتحة العدسة',
          name_en: 'second camera + aperture',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'الكاميرا الثالثة + فتحة العدسة',
          name_en: 'third camera + aperture',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'الكاميرا الرابعة + فتحة العدسة',
          name_en: 'third camera + aperture',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'جودة تصوير الفيديو',
          name_en: 'video shooting quality',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'الفلاش',
          name_en: 'flash',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'خصائص الكاميرا الخلفية',
          name_en: 'back camera features',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'عدد الكاميرات الأمامية',
          name_en: 'number of front cameras',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'الكاميرا الأولى + فتحة العدسة',
          name_en: 'first camera + aperture',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'الكاميرا الثانية + فتحة العدسة',
          name_en: 'first camera + aperture',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'جودة تصوير الفيديو',
          name_en: 'video shooting quality',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'الفلاش',
          name_en: 'flash',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'خصائص الكاميرا الأمامية',
          name_en: 'front camera features',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'WLAN',
          name_en: 'wlan',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'NFC',
          name_en: 'nfc',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'GPS',
          name_en: 'gps',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'USB',
          name_en: 'usb',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'البلوتوث',
          name_en: 'bluetooth',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'الراديو',
          name_en: 'the radio',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'نوع البطارية',
          name_en: 'battery type',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'قدرة البطارية',
          name_en: 'battery capacity',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'قابلة للإزالة',
          name_en: 'removable',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'الشحن السريع',
          name_en: 'fast charging',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'الشحن اللاسلكي',
          name_en: 'wireless charging',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'معلومات عن الشاحن',
          name_en: 'charging info',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'السينسورز الموجودة بالجهاز',
          name_en: 'the sensors on the device',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'نوع البصمة',
          name_en: 'fingerprint type',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'مكان البصمة',
          name_en: 'fingerprint location',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'مكبر الصوت',
          name_en: 'speaker',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'فتحة (السماعات) 3.5mm',
          name_en: 'aperture (attribute) 3.5 mm',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: 'الألوان المتاحة في مصر',
          name_en: 'available colors in egypt',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
      ],
      emptyTaxonomy: [
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [
          ],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [
          ],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
        {
          term_id: '',
          name: '',
          name_en: '',
          locale: '',
          description: null,
          taxDis: false,
          resultTaxonomySearch: [],
        },
      ],
      itemSelect: null,
      itemOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
      ],
      itemSe: true,
      required,
      indexed: 0,
      attrOptions: [],
      valuesSelect: [],
      customToolbar: [
        [{ font: ['cairo'] }],

        [{ header: [false, 1, 2, 3, 4, 5, 6] }],

        [{ size: ['small', false, 'large', 'huge'] }],

        ['bold', 'italic', 'underline', 'strike'],

        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],

        [{ header: 1 }, { header: 2 }],

        ['blockquote', 'code-block'],

        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],

        [{ script: 'sub' }, { script: 'super' }],

        [{ indent: '-1' }, { indent: '+1' }],

        [
          {
            color: [
              '#000000',
              '#0C090A',
              '#34282C',
              '#3B3131',
              '#3A3B3C',
              '#454545',
              '#413839',
              '#3D3C3A',
              '#463E3F',
              '#4C4646',
              '#504A4B',
              '#565051',
              '#52595D',
              '#5C5858',
              '#625D5D',
              '#616D7E',
              '#36454F',
              '#CECECE',
              '#B6B6B4',
              '#8D918D',
              '#808080',
              '#757575',
              '#797979',
              '#C9C0BB',
              '#C0C0C0',
              '#DCDCDC',
              '#D3D3D3',
              '#FAF0E6',
              '#FFF5EE',
              '#FFFFFF',
              '#FEFCFF',
              '#FFFAFA',
              '#FBFBF9',
              '#F5F5F5',
              '#FFFFF7',
              '#FFFFF0',
              '#FFFAF0',
              '#FAF5EF',
              '#FFF5EE',
              '#08308B',
              '#000080',
              '#151B8D',
              '#00008B',
              '#15317E',
              '#0000A0',
              '#0000A5',
              '#0020C2',
              '#0000CD',
              '#00BFFF',
              '#38ACEC',
              '#79BAEC',
              '#82CAFF',
              '#87CEFA',
              '#3BB9FF',
              '#8C9EFF',
              '#954535',
              '#A0522D',
              '#8B4513',
              '#8A4117',
              '#7E3817',
              '#7E3517',
              '#C34A2C',
              '#C04000',
              '#C35817',
              '#B86500',
              '#D2691E',
              '#CC6600',
              '#E56717',
              '#E66C2C',
              '#FF6700',
              '#FF5F1F',
              '#F87217',
              '#F88017',
              '#FF8C00',
              '#ED6009',
              '#FF0000',
              '#FD1C03',
              '#FF2400',
              '#F62217',
              '#F70D1A',
              '#F62817',
              '#E42217',
              '#B71C1C',
            ],
          },
          {
            background: [
              '#000000',
              '#0C090A',
              '#34282C',
              '#3B3131',
              '#3A3B3C',
              '#454545',
              '#413839',
              '#3D3C3A',
              '#463E3F',
              '#4C4646',
              '#504A4B',
              '#565051',
              '#52595D',
              '#5C5858',
              '#625D5D',
              '#616D7E',
              '#36454F',
              '#CECECE',
              '#B6B6B4',
              '#8D918D',
              '#808080',
              '#757575',
              '#797979',
              '#C9C0BB',
              '#C0C0C0',
              '#DCDCDC',
              '#D3D3D3',
              '#FAF0E6',
              '#FFF5EE',
              '#FFFFFF',
              '#FEFCFF',
              '#FFFAFA',
              '#FBFBF9',
              '#F5F5F5',
              '#FFFFF7',
              '#FFFFF0',
              '#FFFAF0',
              '#FAF5EF',
              '#FFF5EE',
              '#08308B',
              '#00BFFF',
              '#38ACEC',
              '#79BAEC',
              '#82CAFF',
              '#87CEFA',
              '#3BB9FF',
              '#8C9EFF',
              '#954535',
              '#A0522D',
              '#8B4513',
              '#8A4117',
              '#7E3817',
              '#7E3517',
              '#C34A2C',
              '#C04000',
              '#C35817',
              '#B86500',
              '#D2691E',
              '#CC6600',
              '#E56717',
              '#E66C2C',
              '#FF6700',
              '#FF5F1F',
              '#F87217',
              '#F88017',
              '#FF8C00',
              '#ED6009',
              '#FF0000',
              '#FD1C03',
              '#FF2400',
              '#F62217',
              '#F70D1A',
              '#F62817',
              '#E42217',
              '#B71C1C',
            ],
          },
        ],

        ['link', 'image', 'video', 'formula'],

        [{ direction: 'rtl' }, { direction: 'ltr' }],
        ['clean'],
      ],
    }
  },
  watch: {
    categorySelect: function (newValue) {
      // console.log(newValue)
      this.itemData.taxonomy = [];

      if (newValue.text === "Mobile Phone") {
        this.itemData.taxonomy = this.standardTaxonomy;
      } else {
        this.itemData.taxonomy = this.emptyTaxonomy;
      }

      this.itemSelect = null
      this.getItems()

      this.fetchAttributesData()


    },
    brandSelect: function () {
      this.itemSelect = null
      this.getItems()
    },
    'itemData.purchse_date': function () {
      for (let index in this.itemData.prices) {
        this.itemData.prices[index].date = this.itemData.purchse_date
      }
    },
  },
  updated() {
    this.mountedform = true
  },
  async created() {
    await this.fetchCreateData()


    for (let index in this.taxLocaleSelect) {
      this.termOptions.push([])
    }
    this.selecteLanguage(0)
    for (let ind in this.termOptions) {
      this.termOptions[ind] = this.termOptions[0]
    }



  },
  methods: {
    fetchCreateData() {
      axios
        .get('items-guide/create')
        .then((result) => {
          const data = result.data.data


          this.categoryOption = data['categories'].map(category => ({ value: category.id.toString(), text: category.name }))
          this.brandOptions = data['brands'].map(brand => ({ value: brand.id.toString(), text: brand.name }))
          this.tagOptions = data['tags'].map(tag => ({ value: tag.id.toString(), text: tag.name }))
          this.currencyOptions = data['currencies'].map(currency => ({ value: currency.id.toString(), text: currency.name }))
          // this.termOptions = data['terms'].map(term => ({value: term.id.toString() , text: term.name , slug: term.slug}))

          // console.log(this.categoryOption)
        })
        .catch((err) => {
          // console.log(err)
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    fetchAttributesData() {
      this.attrOptions = [];
      axios
        .get('categories/' + this.categorySelect.value + '/attributes/get')
        .then((result) => {
          const data = result.data.data

          this.attrOptions = data.map(attr => ({
            value: attr.id,
            text: attr.name,
            values: attr.values.map(value => ({
              text: value.name,
              value: value.id,
            })),
          }));

          this.valuesSelect = Array(data.length).fill([]);


        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData()
      formData.append('image', file)

      axios
        .post('items/image-editor/store', formData)
        .then((result) => {
          showDismissibleAlert = false
          const url = result.data.url // Get url from response
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // submit add form
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.itemData.values = []
          for (let values in this.valuesSelect) {
            for (let val in this.valuesSelect[values]) {
              this.itemData.values.push(this.valuesSelect[values][val].value)
            }
          }
          this.itemData.active = this.activeSelect.value
          this.itemData.status = this.statusSelect.value
          if (this.itemData.cover.includes(',')) {
            this.itemData.cover = this.itemData.cover
              .split(',')
              .map((item) => item.trim())
            this.itemData.cover = this.itemData.cover[1]
          }
          this.itemData.category_id = this.categorySelect.value
          this.itemData.brand_id = this.brandSelect.value
          if (this.itemSelect != null) {
            this.itemData.parent_id = this.itemSelect.value
          }
          this.itemData.tags = []
          for (let tag in this.tagSelect) {
            this.itemData.tags.push(this.tagSelect[tag].value)
          }
          for (let tax in this.itemData.taxonomy) {
            this.itemData.taxonomy[tax].locale = this.taxLocaleSelect[tax].value
            this.itemData.taxonomy[tax].term_id = this.termSelect[tax].value
          }
          for (let pri in this.itemData.prices) {
            this.itemData.prices[pri].currency_id =
              this.currencySelect[pri].value
          }
          for (let trn in this.itemData.translations) {
            this.itemData.translations[trn].locale =
              this.tranLocaleSelect[trn].value
          }
          for (let img in this.itemData.images) {
            if (this.itemData.images[img].file.includes(',')) {
              this.itemData.images[img].file = this.itemData.images[img].file
                .split(',')
                .map((item) => item.trim())
              this.itemData.images[img].file = this.itemData.images[img].file[1]
            }
          }

          // console.log( this.itemData)
          // return;
          // this.itemData.is_guide = this.selected
          axios
            .post('items-guide/store', this.itemData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push({
                path: '/ShowPhoneGuide/' + result.data.data.id,
              })
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    closeOpenSearch() {
      //  this.resultShowBund[this.indexed] = false
    },
    testShow(index) {
      this.resultShowBund[index] = false
    },
    disapperList(index) { },
    searchResultBundle(name, temID, lang, ind, text) {
      this.indexed = ind
      // this.noResultBund = false
      var url = null
      if (text == '') {
        url = 'autocomplete/terms/' + temID + '/taxonomies/' + lang + '/' + name
      } else {
        url =
          'autocomplete/terms/' +
          temID +
          '/taxonomies/' +
          lang +
          '/' +
          name +
          '/' +
          text
      }

      axios
        .get(url)
        .then((result) => {
          const data = result.data.data
          this.itemData.taxonomy[ind].resultTaxonomySearch = data

          if (this.itemData.taxonomy[ind].resultTaxonomySearch.length > 0) {
            this.resultShowBund[ind] = true
          } else {
            this.resultShowBund[ind] = false
          }
        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    // get items
    getItems() {
      if ((this.categorySelect != null && this.categorySelect.value != 'select_value') && (this.brandSelect != null && this.brandSelect.value != 'select_value')) {
        this.itemOptions = [
          {
            value: 'select_value',
            text: 'Select Value',
          },
        ]
        axios
          .get(
            'products/' +
            this.categorySelect.value +
            '/' +
            this.brandSelect.value +
            '/get-items'
          )
          .then((result) => {
            const data = result.data.data
            this.itemSe = false
            if (data.length > 0) {
              for (let ind in data) {
                if (data[ind].item_translations != null) {
                  this.itemOptions.push({
                    text: data[ind].item_translations.name,
                    value: data[ind].id.toString(),
                  })
                }
              }
            }
          })
          .catch((err) => {
            this.errors_back.length = 0
            if (err.response.data.data != null) {
              if (this.isString(err.response.data.data)) {
                this.errors_back.push({
                  error: err.response.data.data,
                })
              } else {
                this.errors_back = err.response.data.data
              }
              this.showDismissibleAlert = true
            } else {
              this.errors_back = []
              this.errors_back.push({
                error: 'internal server error',
              })
              this.showDismissibleAlert = true
            }
          })
      }
    },
    // cencode cover
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.itemData.cover = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // upload function images of item
    uploadImage2(event, index) {
      let files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        this.itemData.images[index].file = []
        return
      } else {
        this.createImage2(files[0], index)
      }
    },
    createImage2(file, ind) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.itemData.images[ind].file = e.target.result
      }
      reader.readAsDataURL(file)
    },
    selecteLanguage(index) {
      // this.itemData.taxonomy[index].taxDis = false
      // fetch terms index
      axios
        .get('terms/locale-index/' + this.taxLocaleSelect[index].value)
        .then((result) => {
          const data = result.data.data
          this.termOptions[index].length = 0
          for (let ter in data) {
            if (data[ter].translation != null) {
              this.termOptions[index].push({
                value: data[ter].id.toString(),
                slug: data[ter].translation.slug,
                text: data[ter].translation.name,
              })
            } else {
              this.termOptions[index].push({
                value: data[ter].id.toString(),
                slug: 'undefinded',
                text: 'undefinded',
              })
            }

            for (let teSelect in this.termSelect) {
              if (
                this.termSelect[teSelect].slug == data[ter].translation.slug
              ) {
                this.termSelect[teSelect].value = data[ter].id.toString()
              }
            }
          }

        })
        .catch((err) => {
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })
    },
    //REPETED FORM
    repeateAgain() {
      //   this.refheightForm = this.$refs.Imagesform
      this.itemDataImages.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.itemData.images.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
    },
    removeItem(index) {
      if (this.itemData.images.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.itemDataImages.splice(index, 1)
        this.itemData.images.splice(index, 1)
      }
    },
    taxonomyrepeateAgain() {
      //    this.refheightForm = this.$refs.Taxonomiesform
      this.taxLocaleSelect.push({
        text: 'Arabic',
        value: 'ar',
      })
      this.itemData.taxonomy.push({
        term_id: '',
        name: '',
        locale: '',
        description: null,
        taxDis: true,
        name_en: '',
        typeCheck: 'new-record',
      })
      this.termSelect.push()
      this.termOptions.push(this.termOptions[0])
    },
    taxonomyremoveItem(index) {
      if (this.itemData.taxonomy.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.itemData.taxonomy.splice(index, 1)
        this.termSelect.splice(index, 1)
        this.taxLocaleSelect.splice(index, 1)
        this.termOptions.splice(index, 1)
      }
    },
    pricesrepeateAgain() {
      //  this.refheightForm = this.$refs.pricesForm
      this.itemData.prices.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      // this.itemData.prices[this.itemData.prices.length - 1].date = this.itemData.purchse_date
      this.currencySelect.push()
    },
    pricesremoveItem(index) {
      if (this.itemData.prices.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.itemData.prices.splice(index, 1)
        this.currencySelect.splice(index, 1)
      }
    },
    translationsrepeateAgain() {
      // this.refheightForm = this.$refs.translationForm
      this.itemData.translations.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.tranLocaleSelect.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
    },
    translationsremoveItem(index) {
      if (this.itemData.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.itemData.translations.splice(index, 1)
        this.tranLocaleSelect.splice(index, 1)
      }
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    findAndReplace(string, target, replacement) {
      var i = 0,
        length = string.length
      for (i; i < length; i++) {
        string = string.replace(target, replacement)
      }
      return string
    },
  },
  beforeRouteLeave(to, from, next) {

    // Display a confirmation dialog
    const confirmLeave = window.confirm('Are you sure you want to leave this page? Any unsaved changes will be lost.');

    // If the user confirms, allow navigation
    if (confirmLeave) {
      next();
    } else {
      // If the user cancels, prevent navigation
      next(false);
    }


  }
}
</script>

<style lang="scss">
.repeater-form {
  transition: 0.35s height;
}

.v-select {
  font-size: 14px;
}

.vs__open-indicator {
  width: 0px;
}

.taxDesc {
  text-align: right !important;
}

//  itemData.taxonomy[index].name != 'البلوتوث' && itemData.taxonomy[index].name != 'دقة الشاشة' && itemData.taxonomy[index].name != 'المعالج' && itemData.taxonomy[index].name != 'عدد انوية المعالج'
#جودة_تصوير_الفيديو {
  text-align: left !important;
}

#البلوتوث {
  text-align: left !important;
}

#دقة_الشاشة {
  text-align: left !important;
}

#المعالج {
  text-align: left !important;
}

#عدد_انوية_المعالج {
  text-align: left !important;
}
</style>
